@import 'components/common/common.scss';

:global(.ccmp-button).resetbtn {
  margin: 0;
  padding: 0;
  height: 20px;

  &:hover {
    background-color: transparent;
  }
}

.modal-success {
  color: $land-green;
  text-align: center;

  :global(.ccmp-button) {
    color: $blue;
    margin: 0 auto;
  }

  p {
    margin: 0 auto 20px;
    max-width: 360px;
  }
}

.login {
  display: flex;
  height: 100vh;

  .largeHero {
    flex-basis: 530px;
    overflow: hidden;
    background: url('../../../assets/bigsky-country.jpg') no-repeat center center;
  }

  .logo-section {
    display: flex;
    justify-content: center;
  }

  .dark-logo,
  .white-logo {
    margin-bottom: 38px;

    img {
      height: 60px;
    }
  }

  .dark-logo {
    display: flex;
  }

  .white-logo {
    display: none;
  }

  .formContainer {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 85px;
    transition: padding 250ms;

    .loginInput {
      width: 500px;
      padding-bottom: 9px;
      height: auto;

      :global(.input-field) {
        height: auto;
        padding-right: 9px;
      }

      input {
        height: 65px;
        font-family: $roboto;
        font-size: 18px;
      }
    }

    .toggle {
      margin: 0 0 20px;
      height: 50px;
    }

    .loginButton {
      width: 500px;
      height: 50px;
    }

    .error {
      width: 500px;
      margin: 18px 0;
      padding: 0 18px;
      height: 18px;
      color: red;
      text-align: center;
      font-family: $roboto;
      font-size: 14px;
    }
  }

  @include responsive-below(1400px) {
    .formContainer {
      background-color: transparent;
    }
  }

  @include responsive-below(990px) {
    .formContainer {
      padding: 39px 39px 39px 39px;
    }
  }

  @include responsive-below(900px) {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 4%, #000000), url('../../../assets/bigsky-country.jpg');
    background-position: center;

    :global(.ccmp-button).resetbtn {
      color: $white;
    }

    .largeHero {
      display: none;
    }


    .toggle {
      color: white;
    }

    .dark-logo {
      display: none;
    }

    .white-logo {
      display: flex;
    }

    .formContainer {
      width: 100%;
      padding: 39px 39px 39px 39px;

      .loginButton {
        width: 100%;
        height: 50px;
      }

      .loginInput {
        width: 100%;
        padding-bottom: 9px;
        height: auto;

        :global(.input-field) {
          height: auto;
        }

        input {
          height: 65px;
          font-family: $roboto;
          font-size: 18px;
        }
      }

      .error {
        width: 100%;
      }
    }
  }

  @include responsive-below(600px) {

    .dark-logo,
    .white-logo {
      img {
        width: 100%;
      }
    }
  }
}
