.map {
  position: relative;
  height: 100%;
  width: 100%;
}

.zoom-wrapper {
  margin-left: 10px;
  height: 80px;
  width: 40px;
}

.container {
  height: 273px;
}
