.edit-link {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2px;
  padding: 2px;
}

.button {
  width: 330px;
}
