@import '../../common.scss';

//Invisible placeholder bar ensures page content does not display behind the icon bar on 601-1401 break points.
.invisible-bar {
  height: 100%;
  width: 72px;
  min-width: 72px;
  display: none;

  @include responsive-above($width-600) {
    display: block;
  }
}

.ccmp-appmenu {
  background-color: $off-white;
  outline: 1px solid $very-light-gray;
  outline-offset: -1px;
  background-color: $off-white;
  color: $off-black;
  order: -1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  top: 48px;
  height: calc(100% - 48px);
  z-index: 2;
  padding: 16px;
  width: 100vw;

  .nav-links {
    justify-content: flex-start;
    align-items: flex-start;
  }

  a {
    text-decoration: none;
    width: 100%;
  }

  .ccmp-button {
    display: flex;
    justify-content: left;
    padding: 0px 8px;
    height: 40px;
    color: $dark-gray;
    font-family: $roboto;
    font-size: 16px;
    line-height: 24px;
    border-radius: 6px;

    .ccmp-icon {
      height: 24px;
      width: 24px;
      padding: 0px;
      margin: 0px;

      img {
        padding: 0px;
        margin: 0px;
        height: 24px;
        width: 24px;
      }
    }

    span {
      margin-left: 12px;
    }
  }

  &.expand {
    .ccmp-button {
      width: 100%;
    }
  }

  &.hidden {
    @include responsive-below(601px) {
      display: none !important;
    }
  }

  .ccmp-iconbutton {
    .ccmp-icon {
      filter: unset;
      img {
        filter: invert(19%) sepia(0%) saturate(95%) hue-rotate(225deg) brightness(100%) contrast(97%);
      }
    }

    .tool-tip {
      display: none;
    }

    &:hover {
      background-color: $hover-green;

      .tool-tip {
        display: flex;
        left: calc(40px + 12px);
      }
    }
  }

  .active-nav {
    background-color: $hover-green;
  }

  .inactive-nav {
    background-color: transparent;
  }
}

.logo-and-hamburger-container {
  display: none;
  cursor: pointer;
}

.top-nav-link-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-bottom: 16px;
}

.bottom-nav-link-section {
  display: flex;
  padding: 16px 0px 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid $gray-20;

  .swap-to-pcc-button {
    display: none;
  }

  .bottom-nav-button {
    background-color: transparent;
    &:hover {
      background-color: $hover-green;
    }
  }
}

.my-account-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

@include responsive-above($width-600) {
  .ccmp-appmenu {
    top: 80px;
    height: 100%;
    top: 0;
    padding: 32px 16px;
    width: 72px;

    &.expand {
      width: 240px;
      box-shadow:
        0px 1px 2px 0px rgba(51, 51, 51, 0.24),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);

      .ccmp-button {
        display: flex;
        justify-content: left;
        padding: 0px 8px;
        height: 40px;
        width: 208px;

        .ccmp-icon {
          height: 24px;
          width: 24px;
          padding: 0px;
          margin: 0px;

          img {
            padding: 0px;
            margin: 0px;
            height: 24px;
            width: 24px;
          }
        }

        span {
          margin-left: 12px;
        }
      }

      .full-logo {
        display: block;
      }

      .logo-collapsed {
        display: none;
      }
    }

    &.collapse {
      .ccmp-button {
        height: 40px;
        width: 40px;
        padding: 0px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .ccmp-icon {
          img {
            padding: 0px;
            margin: 0px;
          }
        }

        .link-text {
          display: none;
        }
      }

      .logo-and-hamburger-container {
        width: 40px;

        .full-logo {
          display: none;
        }

        .logo-collapsed {
          display: block;
          left: 4px;
          position: relative;

          img {
            width: 33px;
            height: 33px;
          }
        }
      }
    }

    .logo-and-hamburger-container {
      display: flex;
    }
  }

  .top-nav-link-section {
    padding: 32px 0px 16px 0px;
  }
}

.allow-full-width {
  @include responsive-above($width-900) {
    .bottom-nav-link-section {
      .swap-to-pcc-button {
        display: flex;
      }
    }
  }

  @include responsive-above($width-1400) {
    .invisible-bar {
      width: 240px;
      min-width: 240px;
    }

    .ccmp-appmenu {
      &.expand,
      &.collapse {
        position: fixed;
        top: 80px;
        height: 100%;
        top: 0;
        padding: 32px 16px 16px;
        width: 240px;
        box-shadow: none;

        .ccmp-button {
          width: 208px;
          flex-direction: row;
          display: flex;
          justify-content: left;
          padding: 0px 8px;

          .link-text {
            display: flex;
          }
        }

        .ccmp-iconbutton {
          .tool-tip {
            display: none;
          }
        }

        .full-logo {
          display: block;
        }

        .logo-collapsed {
          display: none;
        }

        .logo-and-hamburger-container {
          display: flex;

          .full-logo {
            display: flex;
          }

          .logo-collapsed {
            display: none;
          }
        }
      }
    }
  }
}
