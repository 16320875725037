@use '../../common.scss' as *;

.ccmp-selectInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  &.no-padding {
    padding: 0;
  }

  select {
    @include input-field;
    @include font-input;
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &:required:invalid {
      color: $brown-grey;
    }
    @include green-focus-outline;
  }

  .select-container {
    position: relative;
  }

  .input-field-label {
    @include input-field-label;
  }

  .input-field-container {
    @include input-field-container;
  }

  i {
    right: 5px;
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
    position: absolute;
    background-color: white;
    //Pointer events: none needed to ensure the drop down works on click
    pointer-events: none;
    @include vertical-aligned-center;
  }
}
