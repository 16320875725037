@import 'components/common/common.scss';

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  @include font-body;
  background-color: $body-background;
  padding: 0;
  text-align: center;
}

.noscript {
  border: solid black 1px;
  width: 100%;
  height: 70px;
  text-align: center;
  color: red;
}

.app-container {
  display: flex;
  min-height: 100vh;
  height: 100%;

  .route-container {
    flex-basis: 100%;
  }
  @include responsive-below(600px) {
    flex-direction: column;
  }
}
