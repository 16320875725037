// Imports

@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);

// Fonts

$roboto: 'Roboto', sans-serif;
$athelas: 'Athelas', serif;

// Colors

$land-green: #006d31; //This color is the primary color for Land.com and should match the primary green in marketplaces.
$deep-green: #155443;
$black: #000000;
$white: #ffffff;
$deep-blue: #0f47df;
$blue: #1784eb;
$turquoise: #008099;
$perrywinkle: #839ce0;
$ice-blue: #eef1f3;
$ice: #eef3ee;
$off-black: #333333;
$off-white: #f1f3f0;
$gray-20: #cbcbcb;
$gray-30: #bfbfbf;
$gray-40: #979797;
$muted-black: $gray-40;
$gray-50: #666666;
$border-gray: #eaeaea;
$brown-grey: #999999;
$deep-gray: #5b5858;
$dark-gray: #302c2d;
$medium-gray: #757575;
$comfortable-gray: #a0a0a0;
$light-gray: #eeeeee;
$very-light-gray: #dddddd;
$faint-gray: #f1f3f4;
$pale-gray: #e6e6e6;
$muted-gray: rgba(238, 241, 243, 0.5);
$copper: #d34a2b;
$red-40: #e96142;
$pale-red: #e96142;
$pale-brown: #faf5f0;
$hover-green: #d7e5da;
$jungle-green: #004108;
$greenish: #439c5c;
$pale-green: #f1f7f3;
$color-primary: $land-green;
$body-background: $white;
$highlight-background: #f6f9fb;
$rich-black: #0e0d0c;
$blue-spruce: #0e7080;
$color-nav-main-bg: #dddddd;

@mixin land-green-filter {
  img {
    filter: invert(20%) sepia(95%) saturate(2421%) hue-rotate(143deg) brightness(84%) contrast(103%);
  }
}

@mixin white-filter {
  img {
    filter: invert(100%) sepia(0%) saturate(7489%) hue-rotate(59deg) brightness(94%) contrast(116%);
  }
}

@mixin svg-off-black-filter {
  filter: invert(16%) sepia(6%) saturate(0%) hue-rotate(223deg) brightness(94%) contrast(87%);
}

@mixin svg-gray-40-filter {
  filter: invert(67%) sepia(10%) saturate(20%) hue-rotate(6deg) brightness(89%) contrast(85%);
}

@mixin svg-primary-color-filter {
  filter: invert(20%) sepia(95%) saturate(2421%) hue-rotate(143deg) brightness(84%) contrast(103%);
}

@mixin svg-white-filter {
  filter: invert(100%) sepia(0%) saturate(7489%) hue-rotate(59deg) brightness(94%) contrast(116%);
}

@mixin svg-greenish-filter {
  filter: invert(49%) sepia(64%) saturate(372%) hue-rotate(84deg) brightness(94%) contrast(89%);
}

@mixin svg-light-green-filter {
  filter: invert(49%) sepia(64%) saturate(372%) hue-rotate(84deg) brightness(94%) contrast(89%);
}
@mixin button-pressed($color: $jungle-green) {
  background-color: $color;
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}

@mixin button-hover($color: $greenish) {
  background-color: $color;
}

@mixin button-disabled($color: #999999, $bg: $very-light-gray) {
  color: $color;
  background-color: $bg;
}

$standard-border: 1px solid $border-gray;

// Text

$font-h1: 300 60px $roboto;
$font-h2: 300 48px $roboto;
$font-h3: normal 34px $roboto;
$font-h4: normal 24px $roboto;
$font-h5: normal 20px $roboto;

$font-sub: normal 18px $roboto;
$font-sub1: normal 16px $roboto;
$font-sub2: normal 14px $roboto;
$font-sub3: normal 13px $roboto;

$font-mobile-header: 500 16px $roboto;

$font-body: normal 16px $roboto;
$font-body2: normal 14px $roboto;
$font-medium: normal 14px $roboto;
$font-small: normal 12px $roboto;

@mixin font-body() {
  font: $font-body;
  color: $off-black;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

@mixin font-input() {
  @include font-body;
  font: $font-medium;
}

@mixin font-placeholder() {
  font: $font-medium;
  color: $muted-black;
}

@mixin font-error() {
  font: $font-medium;
  color: $pale-red;
}

@mixin font-active() {
  font: $font-medium;
  color: $green;
}

// Spacing
$spacing-sm: 10px;
$spacing-md: 15px;
$spacing-lg: 20px;
$spacing-xl: 25px;
$spacing-xxl: 30px;
$spacing-xxxl: 35px;

// Responsive

$width-320: 320px;
$width-600: 600px;
$width-601: 601px;
$width-728: 728px;
$width-900: 900px;
$width-901: 901px;
$width-990: 990px;
$width-1024: 1024px;
$width-1400: 1400px;
$width-1400: 1400px;
$width-1600: 1600px;

$width-phone-min: $width-320;
$width-phone-max: $width-600;
$width-flex-limit: $width-900;
$width-tablet-max: $width-990;
$width-desktop-max: $width-1400;
$width-ultra-wide: $width-1600;

@mixin responsive-between($min-width, $max-width) {
  @media only screen and (min-width: ($min-width - 0.1)) and (max-width: ($max-width + 0.1)) {
    @content;
  }
}

@mixin responsive-above($breakpoint) {
  @media only screen and (min-width: ($breakpoint + 0.1)) {
    @content;
  }
}

@mixin responsive-below($breakpoint) {
  @media only screen and (max-width: ($breakpoint - 0.1)) {
    @content;
  }
}

// Mixins
@mixin v-align-middle {
  display: flex;
  align-items: center;
}

@mixin xy-centered {
  @include v-align-middle;
  justify-content: center;
}

@mixin vertical-aligned-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin x-aligned-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@mixin box-shadow {
  box-shadow: 0 1px 2px 0 rgba(234, 234, 234, 0.65);
}

@mixin box-shadow-wide {
  box-shadow: -1px 10px 29px 0 rgba(51, 51, 51, 0.3);
}

@mixin box-shadow-wide-transition {
  box-shadow: -1px 10px 29px 0 rgba(51, 51, 51, 0.3);
  transition: box-shadow 0.3s ease-in-out;
}

@mixin bottom-shadow {
  box-shadow: 0 1px 2px 0 rgba(234, 234, 234, 0.65);
  border-bottom: solid 1px #c2c2c2;
}

@mixin bottom-header-shadow {
  box-shadow: 0 7px 13px 0 #e9eef2;
}

@mixin top-shadow {
  box-shadow: 0 -3px 4px 0 #e9eef2;
}

@mixin top-footer-shadow {
  box-shadow: 0 -7px 13px 0 #e9eef2;
}

@mixin thick-shadow {
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

@mixin grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px;

  @include responsive-above(600px) {
    grid-template-columns: 1fr 1fr;
  }

  @include responsive-above(900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@mixin input-field {
  font-size: 16px;
  line-height: 16px;
  padding: 0px 0px 0px 15px;
  border-radius: 2px;
  border: solid 1px $gray-40;
  outline-color: $gray-40;
  outline-width: 0;
  background-color: $white;
  box-sizing: border-box;
  height: 45px;
  display: flex;

  white-space: nowrap;
  overflow: hidden;

  span {
    white-space: nowrap;
    overflow: hidden;
  }
}

@mixin input-field-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 7px 0;
}

@mixin input-field-label {
  margin-bottom: 5px;
}

@mixin button-container-right {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

@mixin button-container-center {
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
}

@mixin button-container-left {
  display: flex;
  justify-content: flex-start;
  margin-top: 25px;
}

@mixin fake-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-right: $spacing-sm;
}

@mixin field-100 {
  width: 100%;
}

@mixin limitTextToLines($lines-to-show: number) {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
}

@mixin error-message {
  height: fit-content;
  margin-right: 5px;
  font-family: $roboto;
  font-size: 14px;
  color: $copper;
}

// Resets

.form-group {
  display: block;
}

.input-field {
  @include input-field;
}

.input-field-container {
  @include input-field-container;

  &.no-padding {
    padding: 0;
  }
}

.input-field-label {
  @include input-field-label;
}

.field-100 {
  @include field-100;
}

.button-container-left {
  @include button-container-left;
}

.button-container-center {
  @include button-container-center;
}

.button-container-right {
  @include button-container-right;
}

.fake-input {
  @include fake-input;
}

.disabled-content {
  opacity: 0.3;
  pointer-events: none;
  touch-action: none;
}

.clamp-lines-2 {
  @include limitTextToLines(2);
}

.clamp-lines-1 {
  @include limitTextToLines(1);
}

// prefixing global common classes
// will have to clean up the rest
.ccmp-bottom-shadow {
  @include bottom-shadow;
}

@mixin error-input-field {
  border: 1px solid $copper;
  color: $copper;
}

@mixin green-focus-outline {
  &:focus-within {
    border: 2px solid $greenish;
  }
}

.ccmp-error-message {
  @include error-message;
}

// Land Font Styles
@mixin header-1 {
  color: $rich-black;
  font-family: $athelas;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}

@mixin header-2 {
  color: $rich-black;
  font-family: $athelas;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

@mixin header-3 {
  color: $rich-black;
  font-family: $roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

@mixin header-4 {
  color: $rich-black;
  font-family: $athelas;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

@mixin subheader-1 {
  color: $rich-black;
  font-family: $roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@mixin subheader-2 {
  color: $rich-black;
  font-family: $roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.072px;
}

@mixin subheader-3 {
  color: $deep-gray;
  font-family: $roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: normal;
}

@mixin body-1-regular {
  color: $dark-gray;
  font-family: $roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

@mixin body-1-light {
  color: $rich-black;
  font-family: $roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.3px;
}

@mixin body-1-medium {
  color: $dark-gray;
  font-family: $roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
}

@mixin body-1-medium-land {
  color: $land-link-blue;
  font-family: $roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@mixin body-2-medium {
  color: $deep-gray;
  font-family: $roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@mixin body-button {
  color: $dark-gray;
  font-family: $roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}

@mixin body-button-medium {
  color: $dark-gray;
  font-family: $roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@mixin caption-1-regular {
  color: $dark-gray;
  font-family: $roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
}

@mixin caption-1-light {
  color: $rich-black;
  font-family: $roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.3px;
}

@mixin caption-1-medium {
  color: $dark-gray;
  font-family: $roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.3px;
}

@mixin caption-2-regular {
  color: $dark-gray;
  font-family: $roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
}

@mixin caption-2-regular-gray {
  color: $medium-gray;
  font-family: $roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
}

@mixin caption-2-light {
  color: $rich-black;
  font-family: $roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.2px;
}

@mixin caption-2-medium {
  color: $dark-gray;
  font-family: $roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.2px;
}

@mixin body-normal {
  color: $dark-gray;
  font-family: $roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@mixin body-medium-link {
  color: $blue-spruce;
  font-family: $roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
}

@mixin body-semibold {
  color: $black;
  font-family: $roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.3px;
}

@mixin body-light {
  color: $dark-gray;
  font-family: $roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.3px;
}

@mixin body-button {
  font-family: $roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}

@mixin caption-medium-link {
  color: $blue-spruce;
  font-family: $roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.3px;
}

$box-shadow-subtle-with-border: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
$box-shadow-deep-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1), 0px 8px 16px 0px rgba(0, 0, 0, 0.1),
  0px 4px 8px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
$box-shadow-extra-deep: 0px 32px 64px 0px rgba(0, 0, 0, 0.10), 0px 16px 32px 0px rgba(0, 0, 0, 0.10);