@import '../common.scss';

.ccmp {
  &-heading {
    text-align: left;
    margin: 0 0 15px;
    font-size: 20px;
    font-weight: 500;
    color: $rich-black;
    font-family: $roboto;

    &-1 {
      font-size: 20px;
      height: 24px;
    }

    &-2 {
      height: 24px;
      font-size: 24px;
      margin: 0 0 12px;
    }

    &-3 {
      font-size: 16px;
      margin: 0 0 10px;
      height: 16px;
    }

    &-r {
      font-size: 24px;
      min-height: 24px;
      height: fit-content;

      @include responsive-below(600px) {
        font-size: 16px;
        min-height: 16px;
        height: fit-content;
      }
    }
  }
}
