@import '../../common.scss';

textarea {
  flex: auto;
  border: none;
  outline: none;
  padding: 0 $spacing-lg 0 0;
  height: 200px;
  max-height: 600px;
  min-height: 25px;
  @include font-input;

  &::placeholder {
    @include font-placeholder;
  }
}

.textarea-div {
  @include input-field;
  @include field-100;

  height: fit-content;
  padding-right: 5px;
  padding-top: $spacing-md;
  padding-bottom: 5px;

  &.error-input-field {
    @include error-input-field;
  }
}
