.menu-button {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 16px;
  border-top: none;
  border-bottom: none;
  border-left: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
  font-size: 16px;
  color: #000;
  background-color: white;
  cursor: pointer;

  &:hover {
    color: rgba(68, 68, 68, 0.75);
  }
}

.dropdown {
  position: relative;
}

.dropdown-items {
  border: 1px solid #d0d0d0;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  height: auto;
  overflow: visible;
  opacity: 1;
  transform: none;
  background: #fff;
  position: absolute;
  top: 100%;
  min-width: 100%;
  z-index: 9;
}

.dropdown-item {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #f9f9f9;
  background-color: white;
  display: block;
  color: #444;
  cursor: pointer;
  font-size: 16px;
  padding: 15px 16px 17px;
  transition: color 0.25s;
  width: 100%;
  display: flex;
  flex-direction: row;

  &:hover {
    color: rgba(68, 68, 68, 0.75);
  }

  img {
    display: inline-block;
    height: 1em;
    margin-right: 0.6em;
    position: relative;
    top: 2px;
    vertical-align: baseline;
    width: 1em;
  }
}
