.tool-bar-wrapper {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #d0d0d0;
  display: flex;
  flex-direction: row;
  position: absolute;
  background-color: white;
  justify-content: space-between;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #d0d0d0;
}

.left-navigation-menu,
.right-navigation-menu {
  display: flex;
  flex-direction: row;
}
