@import '../../common/common.scss';

.ccmp-feedbackmodal {
  @include responsive-above($width-phone-max) {
    height: fit-content;
    max-height: 800px;
  }

  .modal-body {
    margin: 25px 15px 10px;
    @include responsive-above($width-phone-max) {
      margin: 40px 25px 10px;
    }

    img {
      -webkit-user-drag: none;
    }
  }

  .success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font: $font-h3;
    margin-bottom: 55px;

    img {
      height: 120px;
      width: 160px;
      margin: 40px 0;
      @include responsive-above($width-phone-max) {
        height: 300px;
        width: 400px;
        margin: 0;
      }
    }
  }

  .modal-error {
    margin: 10px 0 5px;
  }
}
