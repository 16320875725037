@import '../../common.scss';

.appheader {
  @include box-shadow;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  height: 48px;
  width: 100vw;
  background-color: $white;
  overflow: hidden;

  .hamburger-icon {
    display: flex;
    justify-content: flex-start;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .nav-logo {
    display: flex;
    cursor: pointer;
    color: $off-black;
    font-size: 24px;
    height: 32px;
    padding: 0px 16px 0;
    width: 100%;
    justify-content: center;
  }

  .account-info {
    padding: 0px 16px 0;
    width: 64px;
  }

  .close-button {
    width: 32px;

    display: flex;
    justify-content: center;
    padding: 0 16px;
  }

  @include responsive-above($width-600) {
    display: none;
  }
}
