@use '../../common.scss' as *;

.basic-input {
  //removes up/down arrows from numeric inputs
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  .left-icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-left: -15px;

    i {
      display: flex;

      img {
        width: 39px;
      }
    }
  }

  &.input-field-container {
    @include input-field-container;
  }

  &.no-padding {
    padding: 0;
  }

  .right-icon {
    cursor: pointer;
    height: 45px;
    width: 38px;
    outline: 0;
    margin-left: auto;
    @include vertical-aligned-center;
  }

  .input-field {
    @include input-field;
    @include field-100;
    @include green-focus-outline;

    &.large-input-field {
      height: 65px;
    }
  }

  input {
    display: flex;
    width: 100%;
    outline: none;
    border: none;
    @include font-input;

    &::placeholder {
      @include font-placeholder;
    }
  }

  .error-input-field {
    @include error-input-field;
  }

  .basic-with-icon {
    input {
      padding-left: 38px;
    }
  }

  .input-field-label {
    @include input-field-label;
  }

  &.disabled {
    .input-field-label {
      color: $gray-30;
    }

    cursor: not-allowed;

    .input-field {
      border: 1px solid $gray-30;
      opacity: 0.8;
      input {
        cursor: not-allowed;
        background-color: white;
      }
    }
  }
}
