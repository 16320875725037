@import '../../common.scss';

.ccmp-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 12px;
  border-radius: 3px;
  background: rgba(14, 13, 12, 0.7);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  height: 24px;
  width: fit-content;
  color: var(--White, #fff);
  text-align: center;
  font-family: $roboto;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  z-index: 1;
}
