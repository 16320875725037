@import 'components/common/common.scss';

.ccmp-iconbutton {
  background-color: $color-primary;
  cursor: pointer;
  @include v-align-middle;
  border: none;

  &:focus {
    outline: none;
  }

  &:hover {
    span {
      display: flex;
    }
  }
  i {
    padding: 0;
  }
  &[disabled] {
    .ccmp-icon {
      @include svg-white-filter;
    }
  }
}

.ccmp-iconbutton.ccmp-button-square.white-filter {
  &.ccmp-active,
  &:active,
  &:focus {
    .ccmp-icon {
      @include svg-white-filter;
    }
  }
}
