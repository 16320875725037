@import 'components/common/common.scss';

@mixin ellipsis-text {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 20px;
  width: stretch;
  text-align: left;
}

.account-info-container,
.collapsed {
  cursor: pointer;

  .tooltip {
    left: 64px;
    margin-top: 8px;
    visibility: hidden;
    width: max-content;
  }

  &:hover {
    .tooltip {
      visibility: visible;
    }
  }
}

.account-info-container {
  display: flex;
  padding: 16px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid $pale-gray;
  background: $white;
  width: 100%;
  gap: 12px;
}

.agent-portrait {
  height: 40px;
  width: 40px;
  object-fit: contain;
  border-radius: 4px;
  outline: 1px solid $pale-gray;
  outline-offset: -1px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.agent-contact-info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: flex-start;

  .agent-name {
    font: $font-mobile-header;
    letter-spacing: 0.4px;
    color: $dark-gray;
    @include ellipsis-text;
  }

  .agent-company {
    font: $font-body;
    color: $deep-gray;
    @include ellipsis-text;
  }
}

.collapsed {
  padding: 0;

  .agent-portrait {
    @include responsive-between($width-phone-min, $width-phone-max) {
      height: 32px;
      width: 32px;
    }
  }

  .agent-contact-info {
    display: none;
  }
}
