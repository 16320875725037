@import 'components/common/common.scss';

.custom-overlay {
  width: 172px;
  height: 65px;
  border-radius: 8px;
  box-shadow: $box-shadow-subtle-with-border;
  padding: 10px;
  background-color: $white;
  display: flex;

  button {
    background: transparent;
    border: 0;
    border-radius: 3px;
    display: inline-block;
    font-size: 0.95em;
    outline: none;
    padding: 1px 15px 0;
    margin: 5px;
    font-size: 14px;
    border: 1px solid $black;
    color: $black;
    cursor: pointer;

    &.delete {
      padding: 4px 15px 0;
    }
  }
}
