@import '../common.scss';

.ccmp-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 45px;
  font-size: 16px;
  padding: 10px 30px;
  border-radius: 3px;
  background-color: $color-primary;
  text-decoration: none;
  border: none;
  color: #fff;
  box-sizing: border-box;

  &.ccmp-button-sm {
    height: 35px;
  }

  &.ccmp-button-med {
    height: 45px;

    &.ccmp-button-square,
    &.ccmp-button-square:hover {
      width: 45px;
      padding: 10px 15px;
      margin-right: 15px;
    }
  }

  &.ccmp-button-lg {
    height: 55px;
  }

  &.ccmp-button-wide {
    @include responsive-below($width-phone-max) {
      width: 100%;
      margin: 0;
      padding: 0;
      border-radius: 0 0 3px 3px;
    }
  }

  &.ccmp-button-primary {
    color: #fff;

    //@media hover prevents sticky hover on mobile devices
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $greenish;
      }
    }

    &:active {
      background-color: $jungle-green;
    }

    .ccmp-icon {
      img {
        height: 25px;
        margin-right: 8px;
        margin-left: -15px;
      }
    }
  }

  &.ccmp-button-secondary,
  &.ccmp-button-square {
    color: $gray-50;
    border: solid 1px $gray-40;
    background-color: transparent;
    box-sizing: border-box;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        box-sizing: border-box;
        border: solid 2px $land-green;
        padding: 9px 29px;
        color: $land-green;
      }
    }

    @include green-focus-outline;

    &.ccmp-active,
    &:focus {
      color: $greenish;
      box-shadow: none;
    }

    &:active {
      border: solid 2px $jungle-green;
      color: $jungle-green;
      box-shadow: none;
    }

    &.selected {
      font-weight: bold;
      font-size: 18px;
    }
  }

  &.ccmp-button-tertiary,
  &.ccmp-button-text {
    color: $color-primary;
    background-color: transparent;
    border: none;

    &:active,
    &:focus {
      background-color: $light-gray;
      box-shadow: none;
      border: none;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $light-gray;
        box-shadow: none;
        border: none;
      }
    }
  }

  // todo: This needs to be cleaned out and
  // replaced with just text or link
  // the overlap is considerable.
  &.ccmp-button-link {
    color: $color-primary;
    background-color: transparent;
    border: none;
    height: auto;
    margin: 0;
    padding: 0;
    line-height: normal;
    text-decoration: underline;

    &:active,
    &:hover {
      box-shadow: none;
      border: none;
    }
  }

  &:focus {
    outline: none;
  }

  &.ccmp-button-destructive {
    background-color: $white;
    color: $copper;
    border: 1px solid $copper;

    &:active,
    &:hover {
      color: $red-40;
      border-color: $red-40;
    }

    .ccmp-icon {
      img {
        height: 25px;
        margin-right: 8px;
        margin-left: -15px;
      }
    }
  }

  &[disabled],
  &[disabled]:active,
  &[disabled]:hover {
    cursor: not-allowed;
    color: $gray-50;
    background-color: $light-gray;
    border-color: $light-gray;
  }
}
