@import '../../common/common.scss';

.ccmp-ratinggroup {
  display: flex;
  align-items: flex-start;

  .rating-option {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 0 10px;
    text-align: center;
    cursor: pointer;
    height: auto;
    padding: 0;
    color: $off-black;
    width: 85px;

    @include responsive-above($width-phone-max) {
      margin: 0 30px;
      width: 93px;
    }

    &:hover,
    &:active {
      background-color: transparent;

      .selected-icon-overlay {
        background: #fbe9a3;
      }
    }

    span {
      margin: 0;
      overflow-wrap: break-word;
      white-space: normal;

      @include responsive-above($width-phone-max) {
        margin-top: 12px;
      }
    }

    &.selected {
      font-weight: bold;

      .selected-icon-overlay {
        background: #fbe9a3;
      }
    }
  }

  .icon-container {
    position: relative;
    margin: auto;
    z-index: 0; // creates local stacking context

    img {
      height: 50px;
      width: 50px;

      @include responsive-above($width-phone-max) {
        height: 75px;
        width: 75px;
      }
    }
  }

  .selected-icon-overlay {
    position: absolute;
    top: 3px;
    left: 3px;
    height: 48px;
    width: 47px;
    border-radius: 50%;
    background: transparent;
    z-index: -1;

    @include responsive-above($width-phone-max) {
      top: 4px;
      left: 4px;
      height: 73px;
      width: 72px;
    }
  }
}
