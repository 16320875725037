@use '../../common.scss' as *;

.dropdown-menu-overflow {
  :global(.land-ui-dropdown-menu) {
    padding-left: 1px;
    overflow-y: scroll;
    max-height: 300px;
    outline: 1px solid $blue-spruce;
    outline-offset: -1;
    border: none;
    margin-left: 1.5px;
  }
}
