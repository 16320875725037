@import '../../../common/common.scss';

.button-container {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;

  @include responsive-above($width-phone-max) {
    margin: 25px 0;
  }
}

.button {
  width: 330px;
}

.address-edit-fieldset {
  border: none;
  padding: 0px;
  @include responsive-above($width-flex-limit) {
    display: flex;
    flex-wrap: wrap;
    justify-items: space-between;

    > * {
      flex-basis: calc(49% - 20px);
      margin: 0 14px;
    }
  }
}

.update-address-container {
  margin-top: 10px;
}
