@import '../../common/common.scss';

.ccmp-feedback {
  @include vertical-aligned-center;
  @include font-body;
  align-items: stretch;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;

  .feedback-prompt {
    text-align: center;
    font: $font-h5;
  }

  .ccmp-ratinggroup {
    margin: 30px 0 20px;
    width: auto;
    align-self: center;

    @include responsive-above($width-phone-max) {
      margin: 30px 0;
    }
  }

  .comments-textarea {
    margin-top: 10px;

    textarea {
      min-height: 50px;
      height: 85px;
      resize: vertical;
      overflow: auto;

      @include responsive-above($width-phone-max) {
        resize: vertical;
        height: 100px;
      }
    }
  }
}
