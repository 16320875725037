@import '../common.scss';

.modal-container,
.modal-container-plain {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  max-width: 900px;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(102, 102, 102, 0.15), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;
  
  &.hidden-overflow {
    overflow-y: auto;
    overflow-x: hidden;
  }

  &.visible-overflow {
    overflow-y: visible;
    overflow-x: visible;
  }

  @include responsive-above($width-phone-max) {
    width: 75vw;
    height: auto;
    max-height: 80vh;
    left: 50%;
    top: 15%;
    transform: translate(-50%);
  }

  .modal-header {
    display: flex;
    margin: 0;
    padding: 10px;
    align-items: center;

    @include responsive-above($width-phone-max) {
      justify-content: space-between;
      margin: 20px 25px 0;
      padding: 0;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    margin: 10px 15px;
    text-align: left;

    @include responsive-above($width-phone-max) {
      margin: 10px 25px 20px;
    }
  }

  .scroll-container {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal-footer {
    margin: auto 0 0;
  }

  .title-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .title {
    font: $font-mobile-header;
    margin: 0 0 0 10px;

    @include responsive-above($width-phone-max) {
      font: $font-h4;
      margin: 0;
    }
  }

  .close {
    cursor: pointer;
    display: block;
    object-fit: contain;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 17px;
      height: 17px;
    }

    @include responsive-above($width-phone-max) {
      width: 48px;
      height: 48px;
      img {
        width: 21px;
        height: 21px;
      }
    }
  }

  .close-mobile {
    width: 38px;
    height: 38px;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;

    @include responsive-above($width-phone-max) {
      display: none;
    }
  }

  .ccmp-amenitygroup section {
    @include responsive-below(901px) {
      grid-template-columns: 1fr;
    }
  }
}

.backdrop {
    @include responsive-above($width-phone-max) {
        height: 100vh;
        width: 100vw;
        opacity: 0.6;
        background-color: $rich-black;
        left: 0;
        bottom: 0;
        position: absolute;
        z-index: 10;
    }
}

.bottom-modal-buttons {
  margin-top: auto;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $white;
  padding: 5px 10px;
  border-top: solid 1px $border-gray;
  position: center;
  bottom: 0;

  @include responsive-above($width-phone-max) {
    position: relative;
    padding: 13px 15px;
  }

  button {
    width: 200px;
    margin: 0 5px;
    @include responsive-above($width-phone-max) {
      margin: 0 10px;
    }
  }
}

.modal-container-plain,
.modal-container-plain-with-heading {
  @include responsive-above($width-flex-limit) {
    max-width: 700px;
  }

  .wrapper {
    min-height: 250px;
    margin: 0;

    @include responsive-below($width-phone-max) {
      padding: $spacing-sm;
      padding-bottom: 0;
    }

    .input-container {
      display: flex;
      flex-direction: column;
      font: $font-body;
      text-align: left;
      padding-top: 10px;
      height: auto;

      @include responsive-above($width-phone-max) {
        max-height: calc(80vh - 170px);
        height: 100px;
      }

      .ccmp-validatedinput,
      .ccmp-selectinput,
      .ccmp-heading-3 {
        padding-top: 20px;
        position: relative;
        margin-bottom: 7px;
      }

      h3 {
        min-height: 35px;
      }

      .fake-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }

      .sub-title {
        font: $font-h3;
        padding: 40px;

        @include responsive-below($width-phone-max) {
          padding: 20px;
        }
      }

      .sub-sub-title {
        font: $font-h4;
        padding-top: $spacing-md;
      }
    }
  }
}

.modal-container-plain {
  @include responsive-above($width-phone-max) {
    .modal-header {
      justify-content: flex-end;
    }
    .title {
      display: none;
    }
  }
}

.modal-message {
  height: 100%;
  justify-content: center;
  @include vertical-aligned-center;
  font-size: 20px;
}

.modal-error {
  @include vertical-aligned-center;
  height: 100%;
  justify-content: center;
  color: $pale-red;
  font-size: 20px;
  padding: 0 30px 30px;
}

.photo-editor-modal {
  @include responsive-above(601px) {
    width: fit-content;
    max-width: 75vw;
  }
}
