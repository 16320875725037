.map-and-tool-bar-nav-wrapper {
  height: 350px;
}

.map {
  position: relative;
  height: 300px;
  top: 50px;
  width: 100%;
}

.zoom-wrapper {
  margin-left: 10px;
  height: 80px;
  width: 40px;
}
